import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { decrementCount } from "../Redux/slices/ui";
import { useAppDispatch } from "../Redux/store";
import Line from "../assets/Line 47.png";
import BackButton from "../components/BackButton.js";
import ConfirmationDialog from "../components/ConfirmationDialog";
import ResubmittedDocuments from "../components/DriverDetails/ResubmittedDocuments";
import EnlargedImage from "../components/Image.js";
import PayoutModal from "../components/PayoutModal.js";
import Rating from "../components/Rating.js";
import Table from "../components/Table";
import useVehicleTypes from "../hooks/useVehicleTypes";
import ApiManager from "../utils/ApiManager";
import { VERIFICATION_STATUS } from "../utils/Enums";
import { formatDate, formatPrices } from "../utils/helpers";
import { DriverType } from "./DashboardDrivers";

type StatusType = "approved" | "rejected";

type DriverDetailsType = DriverType & {
  totalEarning: number;
  ratingCount: number;
  rating: number;
  pendingForClearance: number;
};

const DriverDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<DriverDetailsType | null>(null);
  const [isMessageVisible, setMessageVisible] = useState(false);
  const [isPayoutVisible, setPayoutVisible] = useState(false);
  const [isViewingResubmittedDocuments, setIsViewingResubmittedDocuments] =
    useState(false);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { getVehicleLabel } = useVehicleTypes();

  const handleGetDriverDetails = async () => {
    setLoading(true);
    const res: any = await new ApiManager().getDriverDetails(id);
    if (res?.success) {
      setDetails(res.data);
    }
    setLoading(false);
  };

  const closePayoutModal = () => {
    setPayoutVisible(false);
  };

  const [image, setImage] = useState<string | null>(null);
  const [zoomed, setZoomed] = useState(false);

  const changeStatus = async (value: StatusType) => {
    if (!details?.isEmailVerified) {
      setMessageVisible(true);
      return;
    }
    setIsLoading(true);
    const message = {
      approved: "Driver Approved Successfully",
      rejected: "Driver Rejected Successfully",
    };

    const res: any = await new ApiManager().modifyDriver(id || "", {
      verificationStatus: value,
    });

    if (res?.success) {
      handleGetDriverDetails();
      toast(message[value], { type: "success" });
    }
    dispatch(decrementCount());
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetDriverDetails();
  }, []);

  return (
    <div className="py-10 flex flex-col justify-center font-inter bg-gray-100 min-h-screen">
      <BackButton link="/dashboard/drivers" />
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-3xl font-bold mr-4">Driver Details</h1>
        {!loading &&
          details?.verificationStatus ===
            VERIFICATION_STATUS.PENDING_REVIEW && (
            <div>
              <button
                onClick={() => changeStatus("approved")}
                className={`px-8 py-2 rounded-xl ${
                  isLoading
                    ? "opacity-50 cursor-not-allowed"
                    : "opacity-100 cursor-pointer"
                } border border-primary text-primary`}
              >
                Allow
              </button>
              <button
                onClick={() => changeStatus("rejected")}
                className={`px-8 py-2 rounded-xl ml-2 ${
                  isLoading
                    ? "opacity-50 cursor-not-allowed"
                    : "opacity-100 cursor-pointer"
                } border border-red-600 text-red-600`}
              >
                Reject
              </button>
              {!details.isEmailVerified ? (
                <p className="text-sm text-gray-600 mt-2">
                  Email is not verified yet
                </p>
              ) : null}
            </div>
          )}
      </div>
      <div
        style={{ border: "1px solid rgba(228, 228, 231, 1)" }}
        className="w-full p-6 flex  items-center bg-white rounded-lg"
      >
        {loading ? (
          <div className="w-full h-full pb-20 flex justify-center">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <div style={{ width: "40%" }} className=" flex flex-col gap-2">
              <img
                className="w-32 h-32 rounded-full"
                onClick={() => {
                  setImage(details?.profileImage || "");
                  setZoomed(true);
                }}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src =
                    "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png";
                }}
                src={details?.profileImage}
                alt="User"
              />
              <p className="uppercase text-primary font-bold mt-2">
                {details?.fullName}
              </p>
              <p>
                <strong>Total Earning: </strong>

                {formatPrices(details?.totalEarning || 0)}
              </p>
              <div className="flex items-center">
                <strong className="mr-1">Rating: </strong>
                <Rating
                  ratingcount={details?.ratingCount || 0}
                  rating={details?.rating || 0}
                />
              </div>
              <p>
                <strong>Email: </strong> {details?.email}
              </p>
              <p>
                <strong>Joined: </strong> {formatDate(details?.createdAt || "")}
              </p>
            </div>
            <img src={Line} alt="Line" />
            <div style={{ width: "60%" }} className="pl-4 flex flex-col gap-2">
              <p className="font-bold text-primary">Details</p>
              <p>
                <strong>Phone Number: </strong>
                {details?.phone || ""}
              </p>
              <p>
                <strong>Vehicle Number: </strong>
                {details?.vehicleNumber || ""}
              </p>
              <p>
                <strong>Vehicle Type: </strong>
                {getVehicleLabel(details?.vehicleType || "")}
              </p>
              <p>
                <strong>Wallet: </strong>
                {formatPrices(details?.wallet || 0)}
              </p>
              <p>
                <strong>Pending for Clearance: </strong>
                {formatPrices(details?.pendingForClearance || 0)}
              </p>
              <p>
                <strong>Total Orders: </strong>
                {details?.totalOrders || 0}
              </p>
              <p>
                <strong>Completed Orders: </strong>
                {details?.completedOrders || 0}
              </p>
              <div className="flex items-center gap-2">
                {details?.vehicleInsuranceFile ? (
                  <a
                    rel="noreferrer"
                    href={details?.vehicleInsuranceFile}
                    target="_blank"
                  >
                    <button
                      style={{
                        background:
                          "linear-gradient(357.95deg, #339500 1.23%, #77CB02 97.91%)",
                      }}
                      className="px-4 py-2 text-white rounded-lg"
                    >
                      Vehicle Insurance File
                    </button>
                  </a>
                ) : null}
                {details?.vehicleRegistrationFile ? (
                  <a
                    rel="noreferrer"
                    href={details?.vehicleRegistrationFile}
                    target="_blank"
                  >
                    <button
                      style={{
                        background:
                          "linear-gradient(357.95deg, #339500 1.23%, #77CB02 97.91%)",
                      }}
                      className="px-4 py-2 text-white rounded-lg"
                    >
                      Vehicle Registration File
                    </button>
                  </a>
                ) : null}
                {details?.drivingLicenseFile ? (
                  <a
                    rel="noreferrer"
                    href={details?.drivingLicenseFile}
                    target="_blank"
                  >
                    <button
                      style={{
                        background:
                          "linear-gradient(357.95deg, #339500 1.23%, #77CB02 97.91%)",
                      }}
                      className="px-4 py-2 text-white rounded-lg"
                    >
                      Driving License File
                    </button>
                  </a>
                ) : null}
              </div>
              {details?.verificationStatus === VERIFICATION_STATUS.RESUBMIT ? (
                <button
                  onClick={() => setIsViewingResubmittedDocuments(true)}
                  className="text-primary mt-4"
                >
                  View Resubmitted Documents
                </button>
              ) : null}
            </div>
          </>
        )}
      </div>
      <Table driverId={id} />
      <EnlargedImage isVisible={zoomed} setVisible={setZoomed} src={image} />
      <PayoutModal
        driver={details?._id}
        inWallet={details?.wallet}
        isVisible={isPayoutVisible}
        onClose={closePayoutModal}
      />
      <ConfirmationDialog
        messageMode={true}
        isVisible={isMessageVisible}
        onHide={() => setMessageVisible(false)}
        text="Email is not verified yet"
        closeButtonText="Close"
      />
      <ResubmittedDocuments
        onApproveOrRejectSuccess={handleGetDriverDetails}
        isVisible={isViewingResubmittedDocuments}
        onHide={() => setIsViewingResubmittedDocuments(false)}
        driverId={id}
      />
    </div>
  );
};

export default DriverDetails;
