import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import { DriverType } from "../pages/DashboardDrivers";
import { UserType } from "../pages/DashboardUsers";
import ApiManager from "../utils/ApiManager";
import {
  areBothDatesPresent,
  formatPrices,
  isOneDatePresent,
} from "../utils/helpers";
import ConfirmationDialog from "./ConfirmationDialog";
import DataTable, { TBody, TH, THead, TR } from "./DataTable";
import OrdersModal from "./OrdersModal.js";
import Pagination from "./Pagination.js";
import ViewFeedback from "./ViewFeedback";
import Button from "./Button";
import FiltersContainer from "./FiltersContainer";

export const serviceTypeMap: { [key: string]: string } = {
  instore_pickup_meta: "In Store",
  curbside_pickup_meta: "Curb",
  package_pickup_meta: "Package",
};

export type ServiceType = {
  _id: string;
  driver: DriverType[];
  user: UserType[];
  serviceMeta: string;
  serviceMetaType: string;
  status: string;
  driverTotal: number;
  createdAt: string;
  updatedAt: string;
  userTotal: number;
  ratingByUser: {
    feedback: string;
    rating: number;
  };
  curbside_pickup_meta: [];
  package_pickup_meta: [];
  instore_pickup_meta: [];
};

const ServicesDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [isViewApplocationVisible, setViewApplicationVisible] = useState(false);
  const [services, setServices] = useState<ServiceType[]>([]);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState<{
    orderId: string;
    experienceRating: null | number;
    feedback: string;
  }>({
    orderId: "",
    experienceRating: null,
    feedback: "",
  });
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [isCancellingService, setIsCancellingService] = useState(false);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const form = useRef<HTMLFormElement>(null);

  const getAllServices = async () => {
    setLoading(true);
    let res = await new ApiManager().getAllServices({
      limit: 10,
      fromdate: searchParams.get("fromdate") || "",
      todate: searchParams.get("todate") || "",
      status: searchParams.get("status") || "",
      page: parseInt(searchParams.get("page") || `1`),
      type: searchParams.get("type") || "",
    });
    setStatus(searchParams.get("status") || "");
    setType(searchParams.get("type") || "");
    setFromDate(searchParams.get("fromdate") || "");
    setToDate(searchParams.get("todate") || "");
    if (res?.success) {
      setServices(res?.payload?.data?.docs);
      setTotalPage(res?.payload?.data?.totalPages);
    }
    setLoading(false);
  };

  const filterServices = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const newParams = new URLSearchParams();

    for (const [key, value] of formData.entries()) {
      if (value) {
        newParams.append(key, value.toString());
      }
    }

    newParams.set("page", "1");
    navigate(`?${newParams.toString()}`);
  };

  const handlePageClick = (e: { selected: number }) => {
    const page = e.selected + 1;
    searchParams.set("page", page.toString());
    navigate(`?${searchParams.toString()}`);
  };

  const handleServiceCancel = async () => {
    setIsCancellingService(true);
    const res = await new ApiManager().cancelService(selectedServiceId);
    if (res?.status === 200) {
      let s = [...services];
      s.map((item) =>
        item?._id === selectedServiceId ? (item.status = "cancelled") : item
      );
      setServices(s);
      setSelectedServiceId("");
      toast("Service Deleted Successfully", { type: "success" });
    } else {
      setSelectedServiceId("");
      toast("Couldn't cancel service at this moment. Please try again later.", {
        type: "error",
      });
    }
    setIsCancellingService(false);
  };

  const resetFilters = () => {
    setStatus("");
    setType("");
    setFromDate("");
    setToDate("");
    form.current?.reset();
    navigate("");
  };

  const isAnyFilterApplied = () => {
    if (status !== "" || type !== "") return true;

    return false;
  };

  const isApplyButtonDisabled = () => {
    if (isAnyFilterApplied()) {
      return isOneDatePresent(fromDate, toDate);
    }
    return !areBothDatesPresent(fromDate, toDate);
  };

  const isClearButtonDisabled = () => {
    return status === "" && type === "" && fromDate === "" && toDate === "";
  };

  useEffect(() => {
    getAllServices();
  }, [location.search]);

  const statusMap = (status: string, className: string) => {
    const map: { [key: string]: { text: string } } = {
      arrivedAtDropoff: {
        text: "Arrived At Dropoff",
      },
      unassigned: {
        text: "Unassigned",
      },
      notProcessable: {
        text: "Not Processable",
      },
      assigned: {
        text: "Assigned",
      },
      wayToStore: {
        text: "Way To Store",
      },
      arrivedAtPickup: {
        text: "Arrived At Pickup",
      },
      confirmItems: {
        text: "Confirm Items",
      },
      proceedToDropoff: {
        text: "Proceed To Dropoff",
      },
      confirmDropoff: {
        text: "Confirm Dropoff",
      },
      delivered: {
        text: "Delivered",
      },
      ratedByUser: {
        text: "Completed",
      },
      ratedByDriver: {
        text: "Rated By Driver",
      },
      deliver: {
        text: "Deliver",
      },
      completed: {
        text: "Completed",
      },
      cancelled: {
        text: "Cancelled",
      },
    };

    const { text } = map[status];

    return <p className={className}>{text}</p>;
  };

  return (
    <>
      <div className="w-full h-full">
        {/* <Sidebar/> */}
        <Helmet>
          <title>Services Dashboard</title>
        </Helmet>

        <div className="flex py-4 flex-col justify-center font-inter bg-gray-100">
          <FiltersContainer>
            <p className="text-xl my-4 ">Services</p>
            <div className="w-full h-20 flex justify-between items-center">
              <form
                ref={form}
                onSubmit={filterServices}
                className="w-full flex items-center justify-end mb-4"
              >
                <select
                  name="status"
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                  className="w-28 outline-none bg-white rounded-lg h-12 pl-4 pr-2 ml-4"
                >
                  <option value="" disabled hidden>
                    Status
                  </option>
                  <option value="unassigned">Unassigned</option>
                  <option value="assigned">Assigned</option>
                  <option value="wayToStore">Way To Store</option>
                  <option value="arrivedAtPickup">Arrived At Pickup</option>
                  <option value="confirmItems">Confirm Items</option>
                  <option value="proceedToDropoff">Proceed To Dropoff</option>
                  <option value="arrivedAtDropoff">Arrived At Dropoff</option>
                  <option value="confirmDropoff">Confirm Dropoff</option>
                  <option value="completed">Completed</option>
                  <option value="cancelled">Cancelled</option>
                </select>
                <select
                  name="type"
                  onChange={(e) => setType(e.target.value)}
                  value={type}
                  className="w-28 outline-none bg-white rounded-lg h-12 pl-4 pr-2 ml-4"
                >
                  <option value="" disabled hidden>
                    Type
                  </option>
                  <option value="package_pickup_meta">Package</option>
                  <option value="curbside_pickup_meta">Curb</option>
                  <option value="instore_pickup_meta">In Store</option>
                </select>
                <div className="flex items-center ml-4">
                  <label htmlFor="fromdate">From: </label>
                  <input
                    name="fromdate"
                    onChange={(e) => setFromDate(e.target.value)}
                    value={fromDate}
                    id="fromdate"
                    className="w-32 rounded h-12 bg-white ml-2 px-2"
                    type="date"
                  />
                </div>
                <div className="flex items-center ml-4">
                  <label htmlFor="todate">To: </label>
                  <input
                    name="todate"
                    id="todate"
                    className="w-32 rounded h-12 bg-white ml-2 px-2"
                    type="date"
                    onChange={(e) => setToDate(e.target.value)}
                    value={toDate}
                  />
                </div>

                <Button
                  disabled={isApplyButtonDisabled()}
                  type="submit"
                  className={`w-28 ml-4 h-10 rounded-lg text-white `}
                >
                  Apply
                </Button>
                <Button
                  disabled={isClearButtonDisabled()}
                  type="button"
                  onClick={resetFilters}
                  className="btn-outline w-24 ml-4 h-10 bg-transparent border rounded-lg border-primary text-primary"
                >
                  Clear
                </Button>
                <Button
                  type="button"
                  onClick={() => window.location.reload()}
                  className="btn-outline w-32 bg-transparent border border-primary text-primary h-10 ml-4 rounded"
                >
                  Refresh
                </Button>
              </form>
            </div>
          </FiltersContainer>
          <div className="h-4/5 overflow-auto flex flex-col justify-between w-full bg-white rounded-lg">
            {loading === false && services?.length === 0 ? (
              <div className="w-full py-10 h-full flex flex-col items-center justify-center">
                <img className="w-1/5" src={empty} />
                <p className="mt-10 text-2xl text-gray-500 font-inter">
                  No Services Found
                </p>
              </div>
            ) : (
              <DataTable>
                {loading && loading === true ? (
                  <div className="w-full h-full flex items-center justify-center">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <>
                    <THead>
                      <TR>
                        <TH>Driver</TH>
                        <TH>User</TH>
                        <TH className="px-0">Status</TH>
                        <TH>User Total</TH>
                        <TH>Driver Total</TH>
                        <TH>Type</TH>
                        <TH>Actions</TH>
                      </TR>
                    </THead>
                    <TBody>
                      {services?.map((order) => (
                        <TR key={order?._id}>
                          <TH>
                            {order?.driver?.length === 0 ? (
                              <p className="text-md text-black font-normal break-all  ">
                                {order?.driver?.length === 0
                                  ? "N/A"
                                  : order?.driver[0]?.fullName}
                              </p>
                            ) : (
                              <Link
                                to={`/dashboard/drivers/${order?.driver[0]?._id}`}
                              >
                                <p className="text-md font-normal text-black break-all  ">
                                  {order?.driver?.length === 0
                                    ? "N/A"
                                    : order?.driver[0]?.fullName}
                                </p>
                              </Link>
                            )}
                          </TH>
                          <TH>
                            {order?.user?.length === 0 ? (
                              <p className="text-md break-all font-normal text-black ">
                                N/A
                              </p>
                            ) : (
                              <Link
                                to={`/dashboard/users/${order.user[0]?._id}`}
                              >
                                <p className="text-md break-all font-normal text-black ">
                                  {order?.user[0]?.fullName}
                                </p>
                              </Link>
                            )}
                          </TH>
                          <TH
                            className={twMerge(
                              "flex text-md font-normal text-orange-600 break-all p-0 h-full mt-7",
                              order?.status === "cancelled" && "text-red-600",
                              (order?.status === "ratedByUser" ||
                                order?.status === "completed") &&
                                "text-green-600"
                            )}
                          >
                            {statusMap(order.status, "")}
                          </TH>
                          <TH className="font-normal text-black">
                            {order?.userTotal
                              ? formatPrices(order?.userTotal)
                              : "N/A"}
                          </TH>
                          <TH className="font-normal text-black">
                            {formatPrices(order?.driverTotal)}
                          </TH>
                          <TH className="break-all font-normal text-black">
                            {serviceTypeMap[order?.serviceMetaType]}
                          </TH>
                          <TH className="">
                            <Link to={`/dashboard/services/${order?._id}`}>
                              <button className="text-primary h-10  cursor-pointer ">
                                Details
                              </button>
                            </Link>
                            {order?.ratingByUser && (
                              <button
                                onClick={() =>
                                  setSelectedFeedback({
                                    orderId: order?._id,
                                    experienceRating:
                                      order?.ratingByUser?.rating,
                                    feedback: order?.ratingByUser?.feedback,
                                  })
                                }
                                className="text-primary px-4 h-10 ml-4"
                              >
                                View Feedback
                              </button>
                            )}
                            {order?.status ===
                            "ratedByUser" ? null : order?.status ===
                              "cancelled" ? null : (
                              <button
                                onClick={() => setSelectedServiceId(order?._id)}
                                className="text-red-600 ml-4"
                              >
                                Cancel
                              </button>
                            )}
                          </TH>
                        </TR>
                      ))}
                    </TBody>
                  </>
                )}
              </DataTable>
            )}
          </div>
          <div className="mt-6">
            <Pagination
              currentPage={parseInt(searchParams.get("page") || "1")}
              page={totalPage}
              handlePageClick={(e: { selected: number }) => handlePageClick(e)}
            />
          </div>
        </div>
        <OrdersModal
          isVisible={isViewApplocationVisible}
          setVisible={setViewApplicationVisible}
        />
      </div>
      <ViewFeedback
        feedback={selectedFeedback}
        isVisible={selectedFeedback.orderId !== ""}
        screen="driverDetails"
        setVisible={() =>
          setSelectedFeedback({
            experienceRating: null,
            feedback: "",
            orderId: "",
          })
        }
      />
      <ConfirmationDialog
        isSubmitButtonDisabled={isCancellingService}
        isSubmitting={isCancellingService}
        handleClick={handleServiceCancel}
        isVisible={selectedServiceId !== ""}
        onHide={() => setSelectedServiceId("")}
        text="Are you sure you want to cancel this service ?"
      />
    </>
  );
};

export default ServicesDashboard;
