import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useSearchParams } from "react-router-dom";
import Button from "../components/Button";
import ConfirmBlockModal from "../components/ConfirmBlockModal.js";
import CreateUser from "../components/CreateUser";
import DataListView from "../components/DataListView";
import FiltersContainer from "../components/FiltersContainer";
import EnlargedImage from "../components/Image.js";
import UserDetailsModal from "../components/UserDetailsModal.js";
import { instance } from "../utils/ApiManager";
import { areBothDatesPresent, isOneDatePresent } from "../utils/helpers";

const NOT_APPROVED = "not approved";
const BLOCKED = "blocked";

export type UserType = {
  createdAt: string;
  email: string;
  fullName: string;
  gender: string;
  isBlocked: boolean;
  isEmailVerified: boolean;
  phone: string;
  profileImage: string;
  updatedAt: string;
  username: string;
  _id: string;
  totalOrders: number;
  completedOrders: number;
};

const DashboardUsers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState<UserType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<UserType | null>(null);
  const [isCreateUserOpen, setIsCreateUserOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [userBlockId, setUserBlockId] = useState("");
  const [isViewApplocationVisible, setViewApplicationVisible] = useState(false);
  const [isBlockVisible, setBlockVisible] = useState(false);
  const filterFormRef = useRef<HTMLFormElement>(null);
  const searchFormRef = useRef<HTMLFormElement>(null);
  const [key, setKey] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const statusMap: {
    [key: string]: number;
  } = {
    [NOT_APPROVED]: 0,
    [BLOCKED]: 1,
  };

  const getUsers = async () => {
    setLoading(true);
    const res = await instance.get("/admin/user/all", {
      params: {
        limit: 10,
        page: searchParams.get("page") || 1,
        sort: "desc",
        search: searchParams.get("search") || "",
        approved:
          searchParams.get("status") === NOT_APPROVED
            ? statusMap[NOT_APPROVED]
            : "",
        blocked:
          searchParams.get("status") === BLOCKED ? statusMap[BLOCKED] : "",
        searchKey: "fullName",
        fromDate: searchParams.get("fromDate") || "",
        toDate: searchParams.get("toDate") || "",
      },
    });
    if (res.status === 200) {
      setSearch(searchParams.get("search") || "");
      setStatus(searchParams.get("status") || "");
      setFromDate(searchParams.get("fromDate") || "");
      setToDate(searchParams.get("toDate") || "");
      setUsers(res.data.docs);
      setTotalPages(res.data.totalPages);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, [searchParams]);

  const updateUrlParams = (newParams: Record<string, string>) => {
    const updatedParams = new URLSearchParams(searchParams);
    Object.entries(newParams).forEach(([key, value]) => {
      if (value) {
        updatedParams.set(key, value);
      } else {
        updatedParams.delete(key);
      }
    });
    setSearchParams(updatedParams);
  };

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    updateUrlParams({ search: formData.get("search") as string, page: "1" });
  };

  const handleFilter = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    updateUrlParams({
      status: formData.get("status") as string,
      fromDate: formData.get("fromDate") as string,
      toDate: formData.get("toDate") as string,
      page: "1",
    });
  };

  const resetFilters = () => {
    setSearch("");
    setStatus("");
    setFromDate("");
    setToDate("");
    setSearchParams({});
    setKey((prevKey) => prevKey + 1);
    if (searchFormRef.current) {
      searchFormRef.current.reset();
    }
  };

  const handleBlock = async () => {
    if (user?.isBlocked === false) {
      const response = await instance.patch(
        `/admin/user/${userBlockId}/modify`,
        { isBlocked: true }
      );
      if (response.status === 200) {
        setUsers(users.filter((user) => user._id !== userBlockId));
      }
    }
    if (user?.isBlocked === true) {
      const response = await instance.patch(
        `/admin/user/${userBlockId}/modify`,
        { isBlocked: false }
      );
      if (response.status === 200) {
        setUsers(users.filter((user) => user._id !== userBlockId));
      }
    }
  };

  const [zoomed, setZoomed] = useState(false);
  const [image, setImage] = useState("");

  const columns = [
    {
      header: "Username",
      accessor: (user: UserType) => (
        <div className="flex items-center cursor-pointer">
          <div className="flex-shrink-0 w-8 h-8 border border-black rounded-full">
            <img
              onClick={() => {
                setImage(user.profileImage);
                setZoomed(true);
              }}
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src =
                  "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png";
              }}
              className="w-full h-full rounded-full object-cover"
              src={user.profileImage}
              alt={user.fullName}
            />
          </div>
          <div className="w-full relative group">
            <p
              onClick={() => {
                setUser(user);
                setViewApplicationVisible(true);
              }}
              className="ml-2 truncate text-md text-black font-normal"
            >
              {user.fullName}
            </p>
            <p className="bg-gray-100 font-semibold group-hover:opacity-100 opacity-0 absolute group-hover:z-20 z-0 top-0 p-4 text-black">
              {user.fullName}
            </p>
          </div>
        </div>
      ),
    },
    {
      header: "Email",
      accessor: (user: UserType) => (
        <div className="relative group">
          <p className="w-56 2xl:w-72 flex text-black font-normal flex-shrink-0 truncate mr-2 text-md">
            {user.email}
          </p>
          <p className="bg-gray-100 font-semibold group-hover:opacity-100 opacity-0 absolute group-hover:z-20 z-0 top-5 p-4 text-black">
            {user.email}
          </p>
        </div>
      ),
    },
    {
      header: "Phone",
      accessor: (user: UserType) => user.phone,
    },
    {
      header: "Total Orders",
      accessor: (user: UserType) => user.totalOrders,
    },
    {
      header: "Completed Orders",
      accessor: (user: UserType) => user.completedOrders,
    },
    {
      header: "Actions",
      accessor: (user: UserType) => (
        <div className="flex items-center gap-2">
          <Link
            to={`/dashboard/users/${user._id}`}
            className="font-semibold text-primary cursor-pointer"
          >
            View Details
          </Link>
          <span
            onClick={() => {
              setUserBlockId(user._id);
              setUser(user);
              setBlockVisible(true);
            }}
            className="cursor-pointer text-red-500 font-semibold"
          >
            {user.isBlocked === false ? "Block" : "UnBlock"}
          </span>
        </div>
      ),
    },
  ];

  const isAnyFilterApplied = () => {
    if (status !== "") return true;
    return false;
  };

  const isApplyButtonDisabled = () => {
    if (isAnyFilterApplied()) {
      return isOneDatePresent(fromDate, toDate);
    }
    return !areBothDatesPresent(fromDate, toDate);
  };

  const isClearButtonDisabled = () => {
    return status === "" && fromDate === "" && toDate === "" && search === "";
  };

  return (
    <>
      <Helmet>
        <title>Users Dashboard</title>
      </Helmet>
      <div className="w-full h-full flex flex-col">
        <FiltersContainer>
          <div className="w-full h-20 flex justify-between items-center">
            <p className="text-xl font-semibold ">Users</p>
            <form ref={searchFormRef} onSubmit={handleSearch}>
              <input
                onChange={(e) => setSearch(e.target.value)}
                name="search"
                value={search}
                className="w-52 ml-4 px-4 h-12 outline-none rounded "
                placeholder="search"
              />
              <Button
                disabled={search === ""}
                type="submit"
                className="w-32 h-10 ml-4 rounded bg-primary text-white"
              >
                Search
              </Button>
              <Button
                type="button"
                onClick={() => setIsCreateUserOpen(true)}
                className="btn-outline w-32 h-10 ml-4 rounded bg-transparent border-primary border text-primary"
              >
                Add User
              </Button>
            </form>
          </div>
          <form
            key={key}
            ref={filterFormRef}
            onSubmit={handleFilter}
            className="w-full flex items-center justify-end mb-4"
          >
            <div className="flex items-center ml-4">
              <label htmlFor="from_date">From: </label>
              <input
                onChange={(e) => setFromDate(e.target.value)}
                name="fromDate"
                value={fromDate}
                id="from_date"
                className="w-32 rounded h-12 bg-white ml-2 px-2"
                type="date"
              />
            </div>
            <div className="flex items-center ml-4">
              <label htmlFor="to_date">To: </label>
              <input
                name="toDate"
                value={toDate}
                id="to_date"
                onChange={(e) => setToDate(e.target.value)}
                className="w-32 rounded h-12 bg-white ml-2 px-2"
                type="date"
              />
            </div>
            <select
              name="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-32 outline-none bg-white rounded-lg h-12 pl-4 pr-2 ml-4"
            >
              <option value="" disabled hidden>
                Status
              </option>
              <option value={NOT_APPROVED}>Not Verified</option>
              <option value={BLOCKED}>Blocked</option>
            </select>
            <Button
              type="submit"
              className="w-28 ml-4 h-10 bg-primary rounded-lg  text-white"
              disabled={isApplyButtonDisabled()}
            >
              Apply
            </Button>
            <Button
              type="button"
              disabled={isClearButtonDisabled()}
              onClick={resetFilters}
              variant="outline"
              className="w-24 ml-4 h-10 bg-transparent border rounded-lg border-primary text-primary"
            >
              Clear
            </Button>
            <Button
              type="button"
              onClick={() => window.location.reload()}
              className="btn-outline w-32 bg-transparent border border-primary text-primary h-10 ml-4 rounded"
            >
              Refresh
            </Button>
          </form>
        </FiltersContainer>
        <DataListView
          data={users}
          forcePage={parseInt(searchParams.get("page") || "1")}
          columns={columns}
          isFetching={loading}
          emptyStateMessage="No Users Found"
          currentPage={parseInt(searchParams.get("page") || "1")}
          setCurrentPage={(page: number) =>
            updateUrlParams({ page: page.toString() })
          }
          totalPages={totalPages}
        />
      </div>
      <UserDetailsModal
        user={user}
        isVisible={isViewApplocationVisible}
        setVisible={setViewApplicationVisible}
      />
      <ConfirmBlockModal
        name="user"
        blocked={user?.isBlocked}
        blockId={userBlockId}
        handleBlock={handleBlock}
        isVisible={isBlockVisible}
        setVisible={setBlockVisible}
      />
      <EnlargedImage isVisible={zoomed} setVisible={setZoomed} src={image} />
      <CreateUser
        isVisible={isCreateUserOpen}
        onHide={() => setIsCreateUserOpen(false)}
        onSuccess={getUsers}
      />
    </>
  );
};

export default DashboardUsers;
