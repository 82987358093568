import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useSearchParams } from "react-router-dom";
import empty from "../assets/undraw_no_data_re_kwbl.svg";
import AddDriver from "../components/AddDriver";
import ConfirmBlockModal from "../components/ConfirmBlockModal.js";
import DriverDetailsModal from "../components/DriverDetailsModal.js";
import EnlargedImage from "../components/Image.js";
import Pagination from "../components/Pagination.js";
import useVehicleTypes from "../hooks/useVehicleTypes";
import ApiManager, { instance } from "../utils/ApiManager";
import { VERIFICATION_STATUS } from "../utils/Enums";
import Button from "../components/Button";
import { areBothDatesPresent, isOneDatePresent } from "../utils/helpers";
import FiltersContainer from "../components/FiltersContainer";

export type DriverType = {
  createdAt: string;
  drivingLicenseFile: string;
  email: string;
  fullName: string;
  gender: string;
  isBlocked: boolean;
  isEmailVerified: boolean;
  isProfileCompleted: boolean;
  isStripeConnected: boolean;
  phone: string;
  profileImage: string;
  updatedAt: string;
  vehicleInsuranceFile: string;
  vehicleNumber: string;
  vehicleRegistrationFile: string;
  vehicleType: string;
  verificationStatus: string;
  wallet: number;
  _id: string;
  totalOrders: number;
  completedOrders: number;
};

export const highlightedStatuses = [
  VERIFICATION_STATUS.PENDING_REVIEW,
  VERIFICATION_STATUS.RESUBMIT,
];

const DashboardDrivers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const form = useRef<HTMLFormElement>(null);
  const searchForm = useRef<HTMLFormElement>(null);
  const [key, setKey] = useState(0);

  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState<DriverType[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isViewApplocationVisible, setViewApplicationVisible] = useState(false);
  const [isAddDriverOpen, setIsAddDriverOpen] = useState(false);
  const [driver, setDriver] = useState<DriverType | null>(null);
  const [isBlockVisible, setBlockVisible] = useState(false);
  const [driverBlockId, setDriverBlockId] = useState("");
  const [zoomed, setZoomed] = useState(false);
  const [image, setImage] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [secondStatus, setSecondStatus] = useState("");
  const [search, setSearch] = useState("");

  const { getVehicleLabel } = useVehicleTypes();

  const getDrivers = async () => {
    setLoading(true);
    let params: { [key: string]: string | number | boolean } = {
      limit: 10,
      page: searchParams.get("page") || "1",
      searchKey: "email",
      search: searchParams.get("search") || "",
      past: searchParams.get("time") || "",
      verificationStatus: searchParams.get("status") || "",
      fromDate: searchParams.get("fromDate") || "",
      toDate: searchParams.get("toDate") || "",
    };
    setStatus(searchParams.get("status") || "");
    setSecondStatus(searchParams.get("secondStatus") || "");
    setSearch(searchParams.get("search") || "");
    setFromDate(searchParams.get("fromDate") || "");
    setToDate(searchParams.get("toDate") || "");
    const secondStatus = searchParams.get("secondStatus") || "";
    if (secondStatus) {
      const [field, value] = secondStatus.split("-");
      params[field] = value;
    }

    let res = await instance.get("/admin/driver/all", { params });
    if (res.status === 200) {
      setDrivers(res.data?.docs);
      setTotalPages(res.data?.totalPages);
    }
    setLoading(false);
  };

  const handleSearch = (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const newParams = new URLSearchParams(searchParams);

    for (const [key, value] of formData.entries()) {
      if (value) {
        newParams.set(key, value.toString());
      } else {
        newParams.delete(key);
      }
    }

    newParams.set("page", "1"); // Reset to first page on new search
    setSearchParams(newParams);
  };

  useEffect(() => {
    getDrivers();
  }, [searchParams]);

  const handlePageClick = (page: number) => {
    setSearchParams((prev) => {
      prev.set("page", page.toString());
      return prev;
    });
  };

  const handleBlock = async () => {
    if (driver?.isBlocked === false) {
      let res: any = await new ApiManager().blockDriver(driverBlockId, true);
      if (res?.success) {
        setDrivers(drivers.filter((driver) => driver._id !== driverBlockId));
      }
    }
    if (driver?.isBlocked === true) {
      let res: any = await new ApiManager().blockDriver(driverBlockId, false);
      if (res?.success) {
        setDrivers(drivers.filter((driver) => driver._id !== driverBlockId));
      }
    }
  };

  const handleResetSelected = () => {
    setSearch("");
    setStatus("");
    setSecondStatus("");
    setFromDate("");
    setToDate("");
    setSearchParams({});
    setKey((prevKey) => prevKey + 1);
    if (searchForm.current) {
      searchForm.current.reset();
    }
  };

  const isAnyFilterApplied = () => {
    return status !== "" || secondStatus !== "";
  };

  const isApplyButtonDisabled = () => {
    if (isAnyFilterApplied()) {
      return isOneDatePresent(fromDate, toDate);
    }
    return !areBothDatesPresent(fromDate, toDate);
  };

  const isClearButtonDisabled = () => {
    return (
      search === "" &&
      status === "" &&
      secondStatus === "" &&
      fromDate === "" &&
      toDate === ""
    );
  };

  return (
    <>
      <Helmet>
        <title>Drivers Dashboard</title>
      </Helmet>
      <div className="w-full h-full">
        <div className="flex  flex-col justify-center font-inter bg-gray-100">
          <FiltersContainer>
            <div className="w-full  h-20 flex justify-between items-center">
              <p className="text-xl">Drivers</p>
              <form ref={searchForm} onSubmit={handleSearch}>
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  name="search"
                  className="w-52 ml-4 px-4 h-12 outline-none rounded"
                  placeholder="Search"
                />
                <Button
                  disabled={
                    search === "" && searchParams.get("search") === null
                  }
                  type="submit"
                  className="w-32 h-10 ml-4 rounded bg-primary text-white"
                >
                  Search
                </Button>
                <Button
                  onClick={() => setIsAddDriverOpen(true)}
                  className="btn-outline w-32 h-10 ml-4 rounded bg-transparent border border-primary text-primary"
                >
                  Add Driver
                </Button>
              </form>
            </div>
            <form
              key={key}
              ref={form}
              onSubmit={handleSearch}
              className="w-full  flex items-center justify-end mb-4"
            >
              <div className="flex items-center ml-4">
                <label htmlFor="from_date">From: </label>
                <input
                  onChange={(e) => setFromDate(e.target.value)}
                  value={fromDate}
                  name="fromDate"
                  id="from_date"
                  className="w-32 rounded h-12 bg-white ml-2 px-2"
                  type="date"
                />
              </div>
              <div className="flex items-center ml-4">
                <label htmlFor="to_date">To: </label>
                <input
                  onChange={(e) => setToDate(e.target.value)}
                  value={toDate}
                  name="toDate"
                  id="to_date"
                  className="w-32 rounded h-12 bg-white ml-2 px-2"
                  type="date"
                />
              </div>
              <select
                name="status"
                onChange={(e) => setStatus(e.target.value)}
                value={status}
                className="w-56 outline-none bg-white rounded-lg h-12 pl-4 pr-2 ml-4"
              >
                <option value="" disabled hidden>
                  Verification Status
                </option>
                <option value={VERIFICATION_STATUS.APPROVED}>Approved</option>
                <option value={VERIFICATION_STATUS.PENDING_REVIEW}>
                  Pending Approval
                </option>
                <option value={VERIFICATION_STATUS.RESUBMIT}>Resubmit</option>
              </select>
              <select
                name="secondStatus"
                onChange={(e) => setSecondStatus(e.target.value)}
                value={secondStatus}
                className="w-32 outline-none bg-white rounded-lg h-12 pl-4 pr-2 ml-4"
              >
                <option value="" disabled hidden>
                  Status
                </option>
                <option value="emailVerified-1">Email Verified</option>
                <option value="emailVerified-0">Email Not Verified</option>
                <option value="blocked-1">Blocked</option>
              </select>
              <Button
                disabled={isApplyButtonDisabled()}
                type="submit"
                className="w-28 ml-4 h-10 bg-primary rounded-lg text-white"
              >
                Apply
              </Button>
              <Button
                disabled={isClearButtonDisabled()}
                type="button"
                onClick={handleResetSelected}
                className="btn-outline w-24 ml-4 h-10 bg-transparent border rounded-lg border-primary text-primary"
              >
                Clear
              </Button>
              <Button
                type="button"
                onClick={() => window.location.reload()}
                className="btn-outline w-32 bg-transparent border border-primary text-primary h-10 ml-4 rounded"
              >
                Refresh
              </Button>
            </form>
          </FiltersContainer>
          <div className="h-4/5 flex overflow-auto flex-col justify-between w-full bg-white rounded-lg">
            {loading === true ? (
              <div className="w-full h-full flex items-center justify-center">
                <div className="loader"></div>
              </div>
            ) : (
              <>
                {drivers && drivers.length === 0 ? (
                  <div className="w-full py-10 h-full flex flex-col items-center justify-center">
                    <img className="w-1/5" src={empty} />
                    <p className="mt-10 text-2xl text-gray-500 font-inter">
                      No Drivers Found
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="relative w-full h-full overflow-x-auto">
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs h-14 text-gray-700 uppercase bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6">
                              Username
                            </th>
                            <th scope="col" className="px-6">
                              Email
                            </th>
                            <th scope="col" className="px-6">
                              Wallet
                            </th>
                            <th scope="col" className="px-6">
                              Vehicle Number
                            </th>
                            <th scope="col" className="px-6">
                              Vehicle Type
                            </th>
                            <th scope="col" className="px-6">
                              Phone
                            </th>
                            <th scope="col" className="px-6">
                              Total Orders
                            </th>
                            <th scope="col" className="px-6">
                              Completed Orders
                            </th>
                            {/* <th scope="col" className="px-6">
                            Created At
                          </th> */}
                            <th scope="col" className="px-6">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {drivers?.map((driver) => (
                            <tr
                              className={`${
                                highlightedStatuses.includes(
                                  driver.verificationStatus
                                )
                                  ? "bg-green-50"
                                  : "bg-white"
                              }  items-center border-b `}
                            >
                              <th
                                scope="row"
                                className="px-6 flex items-center py-8 font-medium text-gray-900 whitespace-nowrap "
                              >
                                <img
                                  onClick={() => {
                                    setImage(driver?.profileImage);
                                    setZoomed(true);
                                  }}
                                  className="w-10 flex-shrink-0 border border-black mr-4 h-10 object-cover rounded-full"
                                  onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.src =
                                      "https://www.pngfind.com/pngs/m/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.png";
                                  }}
                                  src={driver?.profileImage}
                                />
                                <div className="relative group">
                                  <p
                                    onClick={() => {
                                      setDriver(driver);
                                      setViewApplicationVisible(true);
                                    }}
                                    className="text-black truncate"
                                  >
                                    {driver?.fullName}
                                  </p>
                                  <p className="bg-gray-100 font-semibold group-hover:opacity-100 opacity-0 absolute group-hover:z-20 z-0 -top-4 p-4 text-black">
                                    {driver?.fullName}
                                  </p>
                                </div>
                              </th>
                              <th className="relative px-6 py-4 group">
                                <p className="truncate text-black font-normal">
                                  {driver?.email}
                                </p>
                                <p className="bg-gray-100 font-semibold group-hover:opacity-100 opacity-0 absolute group-hover:z-20 z-0 top-5 p-4 text-black">
                                  {driver.email}
                                </p>
                              </th>
                              <th className="py-6 px-4 font-normal text-black ">
                                ${driver?.wallet?.toFixed(2)}
                              </th>
                              <th className="py-6 px-4 font-normal text-black">
                                {driver?.vehicleNumber || "N/A"}
                              </th>
                              <th className="py-6 px-4 font-normal text-black">
                                {getVehicleLabel(driver?.vehicleType)}
                              </th>
                              <th className="py-6 px-4 font-normal text-black">
                                {driver?.phone}
                              </th>
                              <th className="py-6 px-4 font-normal text-black">
                                {driver?.totalOrders}
                              </th>
                              <th className="py-6 px-4 font-normal text-black">
                                {driver?.completedOrders}
                              </th>
                              {/* <th className="py-6 px-4 font-normal text-black">
                              {driver?.createdAt?.slice(0, 10)}
                            </th> */}
                              <th>
                                <div className="flex items-center px-4">
                                  <Link to={`/dashboard/drivers/${driver._id}`}>
                                    <div className="w-20 flex-shrink-0  text-primary cursor-pointer">
                                      View Details
                                    </div>
                                  </Link>
                                  <div
                                    onClick={() => {
                                      setDriverBlockId(driver._id);
                                      setDriver(driver);
                                      setBlockVisible(true);
                                    }}
                                    className="w-20 flex-shrink-0 cursor-pointer text-red-500 ml-4"
                                  >
                                    {driver?.isBlocked ? "UnBlock" : "Block"}
                                  </div>
                                </div>
                              </th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div className="my-6">
            <Pagination
              currentPage={parseInt(searchParams.get("page") || "1", 10)}
              page={totalPages}
              handlePageClick={(e: { selected: number }) =>
                handlePageClick(e.selected + 1)
              }
            />
          </div>
        </div>
        <DriverDetailsModal
          user={driver}
          isVisible={isViewApplocationVisible}
          setVisible={setViewApplicationVisible}
        />
        <ConfirmBlockModal
          name="driver"
          handleBlock={handleBlock}
          blocked={driver?.isBlocked}
          isVisible={isBlockVisible}
          setVisible={setBlockVisible}
          blockId={driverBlockId}
        />
        <AddDriver
          isVisible={isAddDriverOpen}
          onHide={() => setIsAddDriverOpen(false)}
          onSuccess={getDrivers}
        />
        <EnlargedImage isVisible={zoomed} setVisible={setZoomed} src={image} />
      </div>
    </>
  );
};

export default DashboardDrivers;
